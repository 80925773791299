import {inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AbstractService {
  /** Services */
  protected http: HttpClient = inject(HttpClient);

  /** Variavels */
  protected url: string = environment.apiUrl;
  protected urI: string = ''
  constructor() { }
  public index(params?:any): Observable<any> {
    return this.http.get(`${this.url}/${this.urI}`, {params: params})
  }

  public show(id:number): Observable<any> {
    return this.http.get(`${this.url}/${this.urI}/${id}`)
  }

  public store(params:any): Observable<any> {
    return this.http.post(`${this.url}/${this.urI}`, params);
  }

  public update(id:any, params:any): Observable<any> {
    return this.http.put(`${this.url}/${this.urI}/${id}`, params);
  }

  public delete(id:number): Observable<any> {
    return this.http.delete(`${this.url}/${this.urI}/${id}`)
  }
}
