import {Injectable, signal, Signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  public darkMode: WritableSignal<boolean> = signal<boolean>(false);
  constructor() { }
}

export const darkModeService = new DarkModeService().darkMode;
